import React from 'react'
import * as styles from './section-dropdown.module.scss'

import Icon from '../../../static/dropdown-circle.svg'

const SectionDropdown = ({
    data,
    index
}) => {
    const {
        title,
        description
    } = data
    return (
        <div className={`container container-lg ${styles.container}`}>
            <input
                type="checkbox"
                name=""
                id={`${title}-${index}`}
                className={styles.checkbox}
                hidden
            />
            <label htmlFor={`${title}-${index}`} className={styles.label}>
                <span className={styles.title}>{title}</span>
                <div className={styles.empty}>&nbsp;</div>
                <Icon className={styles.icon} />
            </label>
            <p className={styles.content}>
                {description?.description}
            </p>
        </div>
    )
}

export default SectionDropdown