import React from 'react'
import * as styles from './section-tabs.module.scss'

import ButtonPrimary from '../../Shared/ButtonPrimary/ButtonPrimary'

const SectionTabs = ({ data }) => {
    const {
        childrenItems
    } = data
    return (
        <div className='container container-xl'>
            <div className={styles.grid}>
                {
                    childrenItems.map(({ buttonLink, buttonColor }, index) => (
                        <div key={index}>
                            <ButtonPrimary
                                text={buttonLink?.title}
                                link={buttonLink?.target?.slug}
                                extraClass={` ${styles.button} ${buttonColor ? styles.colored : ''}`}
                            />
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default SectionTabs