// extracted by mini-css-extract-plugin
export var cardDesc = "section-grid-module--card-desc--KRuhl";
export var cardImage = "section-grid-module--card-image--xpQbW";
export var cardItem = "section-grid-module--card-item--jfg5z";
export var cardTitleContainer = "section-grid-module--card-title-container--u9W4U";
export var coloredButton = "section-grid-module--colored-button--EgOn6";
export var contentContainer = "section-grid-module--content-container--XnabY";
export var empty = "section-grid-module--empty--mpVw3";
export var grid = "section-grid-module--grid--aUjuW";
export var gridDesc = "section-grid-module--grid-desc--EPDKA";
export var gridTitle = "section-grid-module--grid-title--l0zAW";
export var inside = "section-grid-module--inside--W4okh";
export var itemButton = "section-grid-module--item-button--4jg5j";
export var itemContainer = "section-grid-module--item-container--HT4Tm";
export var itemContent = "section-grid-module--item-content--ShyTm";
export var itemDesc = "section-grid-module--item-desc--WH0GJ";
export var itemGrid = "section-grid-module--item-grid--+T1oJ";
export var itemTitle = "section-grid-module--item-title--PhCr7";
export var title = "section-grid-module--title--83NtG";
export var title_border = "section-grid-module--title_border--uRnVf";
export var title_center = "section-grid-module--title_center--iO8Fd";
export var title_left = "section-grid-module--title_left--fMvF6";
export var title_right = "section-grid-module--title_right--hPfh5";