import React from 'react'
import { graphql } from 'gatsby'

import Seo from '../components/seo';
import HeroComponent from '../components/sections/HeroComponent/HeroComponent';
import SectionBreadCrumbs from '../components/sections/SectionBreadCrumbs/SectionBreadCrumbs';
import SubSection from '../components/sections/SubSection/SubSection';
import SectionContent from '../components/sections/SectionContent/SectionContent';
import SectionGrid from '../components/sections/SectionGrid/SectionGrid';
import SectionAlternateGrid from '../components/sections/SectionAlternateGrid/SectionAlternateGrid';
import RecipeCollection from '../components/sections/RecipeCollection/RecipeCollection';
import ComponentHeroBackground from '../components/sections/ComponentHeroBackground/ComponentHeroBackground';
import SectionTabs from '../components/sections/SectionTabs/SectionTabs';
import SectionTiles from '../components/sections/SectionTiles/SectionTiles';
import RecipeComponent from '../components/sections/RecipeComponent/RecipeComponent';
import SectionMedia from '../components/sections/SectionMedia/SectionMedia';
import SectionSlider from '../components/sections/SectionSlider/SectionSlider';
import SectionDropdown from '../components/sections/SectionDropdown/SectionDropdown';
import SectionDownloadable from '../components/sections/SectionDownloadable/SectionDownloadable';

const Template = ({
    pageContext,
    location,
    data: {
        contentfulPageTemplate: {
            seoTitle,
            seoDescription,
            slug,
            pageTitle,
            sections,
            seoImage,
            noIndex,
            missingH1
        }
    }
}) => {
    // console.log(sections);

    return (
        <div>
            {
                missingH1
                    ? <h1 style={{ display: 'none' }}>{seoTitle}</h1>
                    : null
            }
            <Seo
                title={seoTitle}
                description={seoDescription?.seoDescription}
                slug={slug}
                lang={pageContext.node_locale}
                dynamicSeoImage={seoImage}
                noIndex={!!noIndex}
            />
            <div className='content'>
                {
                    sections?.map((section, index) => {
                        switch (section.__typename) {
                            case "ContentfulComponentHero":
                                return (
                                    <HeroComponent
                                        data={section}
                                        index={index}
                                        key={index}
                                        first_child={index === 0}
                                    />
                                )
                            case "ContentfulSectionBreadCrumbs":
                                return (
                                    <SectionBreadCrumbs data={section} key={index} title={pageTitle} />
                                )
                            case "ContentfulSubSection":
                                return (
                                    <SubSection data={section} key={index} />
                                )
                            case "ContentfulSectionContent":
                                return (
                                    <SectionContent data={section} key={index} />
                                )
                            case "ContentfulSectionGrid":
                                return (
                                    <SectionGrid data={section} key={index} />
                                )
                            case "ContentfulSectionAlternateGrid":
                                return (
                                    <div key={index} id={`template-${index}`}>
                                        <SectionAlternateGrid data={section} />
                                    </div>
                                )
                            case "ContentfulComponentRecipeCollection":
                                return (
                                    <RecipeCollection data={section} key={index} />
                                )
                            case "ContentfulComponentBackgroundHero":
                                return (
                                    <div key={index}>
                                        <ComponentHeroBackground data={section} />
                                    </div>
                                )
                            case "ContentfulComponentMenuGroup":
                                return (
                                    <SectionTabs data={section} key={index} />
                                )
                            case "ContentfulComponentTiles":
                                return (
                                    <SectionTiles data={section} key={index} />
                                )
                            case "ContentfulSectionRecipeComponent":
                                return (
                                    <RecipeComponent data={section} key={index} />
                                )
                            case "ContentfulComponentMedia":
                                return (
                                    <SectionMedia data={section} key={index} />
                                )
                            case "ContentfulSectionSlider":
                                return (
                                    <SectionSlider data={section} key={index} />
                                )
                            case "ContentfulSectionDropDown":
                                return (
                                    <SectionDropdown data={section} key={index} index={index} />
                                )
                            case "ContentfulSectionDownloadable":
                                return (
                                    <SectionDownloadable data={section} key={index} />
                                )
                        }
                    })
                }
            </div>
        </div>
    )
}

export const TemplateQuery = graphql`
    query TemplateQuery( $id: String! ){
        contentfulPageTemplate(id: {eq: $id}){
            seoTitle
            seoDescription {
              seoDescription
            }
            slug
            pageTitle
            seoImage {
                file {
                    url
                }
            }
            noIndex
            missingH1
            sections {
                __typename
                ...sectionDropdown
                ...recipeCollection
                ...componentHero
                ...sectionsBreadcrumbs
                ...subSection
                ...alternateGrid
                ...sectionContent
                ...sectionGrid
                ...componentBackgroundHero
                ...componentMenu
                ...componentTiles
                ...sectionRecipeComponent
                ...componentMedia
                ...sectionSlider
                ...sectionDownloadable
            }
        }        
    }
`

export default Template