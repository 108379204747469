import React from 'react'
import * as styles from './section-downloadable.module.scss'

import Icon from '../../../static/file-pdf.svg'
const SectionDownloadable = ({
    data
}) => {
    const {
        downloadableSections
    } = data
    return (
        <div className={`container container-xl ${styles.container}`}>
            <div className={`${styles.downloadableContainer}`}>
                {
                    downloadableSections.map(({ title, description, buttonLink }, index) => (
                        <div className={styles.item}>
                            <p className={styles.title}>{title}</p>
                            <p className={styles.desc}>{description?.description}</p>
                            <a href={buttonLink?.file?.file?.url}>
                                <span className={styles.anchor}>{buttonLink?.title}</span>
                                <Icon className={styles.icon} />
                            </a>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default SectionDownloadable