import React from 'react'
import * as styles from './component-hero-background.module.scss';

import { GatsbyImage } from 'gatsby-plugin-image';

const ComponentHeroBackground = ({
    data
}) => {
    const {
        title,
        description,
        heroImage,
        mobileHeroImage,
        opacity,
        position
    } = data
    return (
        <div className={`${styles.container} ${position === 'left' ? styles.left : styles.right}`}>
            <div className={styles.imageContainer}>
                <GatsbyImage
                    image={heroImage.gatsbyImageData}
                    alt={title}
                    className={styles.desktopImage}
                />
                <GatsbyImage
                    image={(mobileHeroImage || heroImage).gatsbyImageData}
                    alt={title}
                    className={styles.mobileImage}
                />
                <div className={styles.overlay} style={{ opacity: opacity }}>&nbps;</div>
                <p className={`${styles.titleMobile} ${title ? styles.titleBorder : ''}`}>
                    {
                        title?.split('/n').map((phrase, index) => (
                            <span key={index} style={{ display: 'block' }}>{phrase}</span>
                        ))
                    }
                </p>
            </div>
            <div className={styles.contentContainer}>
                <p className={`${styles.title} ${title ? styles.titleBorder : ''}`}>
                    {
                        title?.split('/n').map((phrase, index) => (
                            <span key={index} style={{ display: 'block' }}>{phrase}</span>
                        ))
                    }
                </p>
                <p className={styles.description}>{description?.description}</p>
            </div>
            <div className={styles.descMobile}>
                <p>{description?.description}</p>
            </div>
        </div>
    )
}

export default ComponentHeroBackground