// extracted by mini-css-extract-plugin
export var colored = "section-content-module--colored--law+s";
export var container = "section-content-module--container--CAZ0d";
export var content = "section-content-module--content--sOzJg";
export var content_bolder = "section-content-module--content_bolder--EA-dt";
export var content_center = "section-content-module--content_center--14nFK";
export var content_left = "section-content-module--content_left--CCyp5";
export var content_right = "section-content-module--content_right--z8LZ6";
export var extra_bolder = "section-content-module--extra_bolder--mViHm";
export var space_between_rows = "section-content-module--space_between_rows--896BP";
export var table_borders = "section-content-module--table_borders--CvsM-";
export var table_grid_3 = "section-content-module--table_grid_3--lpdjI";
export var title = "section-content-module--title--yz8cT";
export var title_center = "section-content-module--title_center--W1cZt";
export var title_left = "section-content-module--title_left--mpxHv";
export var title_right = "section-content-module--title_right--pVErr";