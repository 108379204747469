import React from 'react'
import * as styles from './section-tiles.module.scss'

import { GatsbyImage } from 'gatsby-plugin-image'

import HeadingLevel from '../../Shared/HeadingLevel/HeadingLevel'

const SectionTiles = ({ data }) => {
    const {
        title,
        tiles
    } = data
    return (
        <div className={`container container-xl ${styles.container}`}>
            <HeadingLevel title={title} headingLevel={3} extraClass={styles.title} />
            <div className={styles.grid}>
                {
                    tiles.map(({ title, image, description }, index) => (
                        <div className={styles.tileContainer} key={index}>
                            <div className={styles.imageContainer}>
                                <GatsbyImage
                                    image={image.gatsbyImageData}
                                    alt={title}
                                    className={styles.image}
                                />
                                <p className={styles.tileTitle}>{title}</p>
                            </div>
                            <p className={styles.desc}>{description?.description}</p>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default SectionTiles