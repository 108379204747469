import React from 'react'
import * as styles from './section-content.module.scss';

import { RichText } from '../../Shared/RichText/RichText';

import HeadingLevel from '../../Shared/HeadingLevel/HeadingLevel';
import ButtonPrimary from '../../Shared/ButtonPrimary/ButtonPrimary';

const SectionContent = ({
    data
}) => {
    const {
        content,
        containerSize,
        title,
        headingLevel,
        titleAlignment,
        contentAlignment,
        backgroundColor,
        extraClasses,
        buttonLink
    } = data
    return (
        <div className={`container container-${containerSize} ${styles.container} ${styles[extraClasses]}`}>
            {
                title
                    ? <HeadingLevel title={title} headingLevel={headingLevel} extraClass={`${styles.title} ${styles[`title_${titleAlignment}`]}`} />
                    : <></>
            }
            <div className={`recipe-space ${styles.content} ${styles[`content_${contentAlignment}`]} ${backgroundColor ? styles.colored : ''}`}>
                <RichText content={content} />
            </div>
            {
                buttonLink && <div>
                    <ButtonPrimary text={buttonLink.title} link={buttonLink.slug || buttonLink.target?.slug} url={buttonLink.externalLink} />
                </div>
            }
        </div>
    )
}

export default SectionContent