import React from 'react'
import * as styles from './recipe-component.module.scss'

import RecipeDescription from '../../Recipe/RecipeDescription/RecipeDescription'
import { useSiteDictionary } from '../../../hooks'

const RecipeComponent = ({ data }) => {

    const {
        ymMaterial,
        ymHowToMake
    } = useSiteDictionary()

    const {
        title,
        ingredients,
        method,
        note
    } = data

    return (
        <div className={`triangle ${styles.container}`}>
            <div className='container container-lg'>
                <p className={styles.title}>
                    {
                        title.split('/n').map((phrase, key) => (
                            <span key={key} style={{ display: 'block' }}>{phrase}</span>
                        ))
                    }
                </p>
                <RecipeDescription
                    method={ingredients}
                    methodTitle={ymHowToMake}
                    ingredients={method}
                    ingredientsTitle={ymMaterial}
                />
                <p className={styles.note}>{note?.note}</p>
            </div>
        </div>
    )
}

export default RecipeComponent