// extracted by mini-css-extract-plugin
export var container = "component-hero-background-module--container--+SdF1";
export var contentContainer = "component-hero-background-module--content-container--Lm3o0";
export var descMobile = "component-hero-background-module--desc-mobile--Yp9Pi";
export var description = "component-hero-background-module--description--l0sZU";
export var desktopImage = "component-hero-background-module--desktop-image--Ef6Sm";
export var imageContainer = "component-hero-background-module--image-container--jxr5p";
export var left = "component-hero-background-module--left--TdFhI";
export var mobileImage = "component-hero-background-module--mobile-image--qECcN";
export var overlay = "component-hero-background-module--overlay--V80e-";
export var right = "component-hero-background-module--right--GAKH7";
export var title = "component-hero-background-module--title--8Q2YB";
export var titleBorder = "component-hero-background-module--title-border--Je-vp";
export var titleMobile = "component-hero-background-module--title-mobile--dcvLm";