import React from 'react'
import * as styles from './section-media.module.scss';

import HeadingLevel from '../../Shared/HeadingLevel/HeadingLevel';

const SectionMedia = ({
    data
}) => {
    const {
        title,
        titleBorder,
        headingLevel,
        youtubeEmbeddedCode
    } = data
    return (
        <div className={`container container-lg ${styles.container}`}>
            <HeadingLevel title={title} headingLevel={headingLevel} extraClass={`${styles.title} ${titleBorder ? styles.border : ''}`} />
            <div className={styles.videoContainer}>
                <iframe
                    width="673"
                    height="376"
                    src={`https://www.youtube-nocookie.com/embed/${youtubeEmbeddedCode}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                />
            </div>
        </div>
    )
}

export default SectionMedia