import React from 'react'
import { useSiteDictionary } from '../../../hooks'
import { RichText } from '../../Shared/RichText/RichText'
import * as styles from './recipe-description.module.scss'

const RecipeDescription = ({
    method,
    ingredients,
    methodTitle,
    ingredientsTitle
}) => {
    return (
        <div className={`container container-lg ${styles.container}`}>
            <div className={`recipe-space ${styles.ingredients}`}>
                <h3 className={styles.title}>{ingredientsTitle}</h3>
                <RichText content={ingredients} />
            </div>
            <div className={`recipe-space ${styles.method}`}>
                <h3 className={styles.title}>{methodTitle}</h3>
                <RichText content={method} />
            </div>
        </div>
    )
}

export default RecipeDescription